import React from 'react';

import { PAGES } from '../../utils/utils';
import SeparationDivorce from '../../components/fr/competences/separation-divorce';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const SeparationDivorcePage = () => (
  <Layout page={PAGES.COMPETENCES_SEPARATION_DIVORCE}>
    <SEO title="Séparation-Divorce" />
    <SeparationDivorce />
  </Layout>
);

export default SeparationDivorcePage;
