import React from 'react';

import { getYearsSinceOfficeBirth } from '../../../utils/utils';
import CompetencesContainer, {
  COMPETENCES,
  genererFilAriane,
} from './container';

const SeparationDivorce = () => {
  return (
    <CompetencesContainer currentSection={COMPETENCES.SEPARATION_DIVORCE}>
      <h1>Séparation-Divorce</h1>

      {genererFilAriane('séparation-divorce')}

      <hr />

      {/* Description */}

      <div id="texteImg">
        <img
          className="divorce"
          src="/img/competences_sprite.jpg"
          alt="Séparation-Divorce"
        />
      </div>

      <p>
        Une <span className="accent">séparation</span> ou un{' '}
        <span className="accent">divorce</span> constitue souvent un
        bouleversement majeur et apporte son lot de questions :
        qu'adviendra-t-il de la{' '}
        <span className="accent2">garde des enfants</span>, qui subviendra à
        leurs besoins et à concurrence de quel montant, que faire de la{' '}
        <span className="accent2">résidence familiale</span>, qui remboursera
        les dettes familiales, puis-je exiger une{' '}
        <span className="accent2">pension alimentaire</span> de mon conjoint ou
        devrai-je verser une pension alimentaire?
      </p>
      <p>
        {' '}
        Nos avocats expérimentés, dont l'un compte{' '}
        <span className="accent">
          <span id="anneesExp">{getYearsSinceOfficeBirth()}</span> ans
          d'expérience
        </span>{' '}
        dans le domaine, verront à vous guider tout au long du processus et
        travailleront efficacement avec vous à la recherche des meilleures
        solutions pour vous dans un climat d'écoute et de professionnalisme.
      </p>
    </CompetencesContainer>
  );
};

SeparationDivorce.propTypes = {};

export default SeparationDivorce;
